// tabs custom

.region_tabs {
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-tab {
      margin: 0;
      padding: 10px 0;
      width: 130px;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: $gray5;
      border-bottom: 1px solid $gray5;
    }
    .ant-tabs-tab-active {
      font-weight: 700;
      font-size: 14px;
      border-bottom-color: $primary;
      .ant-tabs-tab-btn {
        color: $primary;
        border-bottom-color: $primary;
      }
    }
    .ant-tabs-ink-bar {
      background-color: $primary;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
}
.profile_tabs {
  .ant-tabs-nav {
    margin: 0;
    .ant-tabs-tab {
      margin: 0;
      padding: 10px 0;
      width: 30vw;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: $gray5;
      border-bottom: 1px solid $gray5;
    }
    .ant-tabs-tab-active {
      font-weight: 500;
      font-size: 14px;
      border-bottom-color: $primary;
      .ant-tabs-tab-btn {
        color: $primary;
        border-bottom-color: $primary;
      }
    }
    .ant-tabs-ink-bar {
      background-color: $primary;
    }
  }
  .ant-tabs-nav-operations {
    display: none !important;
  }
}

// swiper custom
.store_swiper {
  .swiper-wrapper {
    .swiper-slide {
      width: 25vw !important;
      height: 25vw !important;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .swiper-button-next {
    visibility: hidden;
  }
  .swiper-button-prev {
    visibility: hidden;
  }
}
.payment_radio {
  :where(.css-dev-only-do-not-override-qgg3xn).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    border-color: $primary;
    background-color: $primary;
  }
}
.deleteAccount {
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper:focus-within,
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #d0d5dd !important;
    box-shadow: none;
  }
  button {
    div {
      display: none;
    }
    &:focus-visible {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}

//Ngoc Na
//select
.ant-select-selector:hover,
.ant-select-selector:focus-visible,
.ant-select-selector:focus-within,
//datepicker
.ant-picker:hover,
.ant-picker:focus-visible,
.ant-picker:focus-within,
.ant-picker-focused,
//input no form
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within,
//input with form
.ant-input:hover,
.ant-input:focus {
  border-color: #d0d5dd !important;
  box-shadow: none !important;
}
.ant-form-item-control-input-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-btn:focus-visible {
  outline-offset: 0 !important;
  outline: 0 !important;
}
// .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
//   border-color: transparent !important;
// }
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: $primary !important;
  }
}
